import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

import GlobalStore from '../../../store';

// components
import PipsListView from '../components/PipsListView';
import NewFilters from '../../../components/NewFilters';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import SubHeader from '../../../components/SubHeader';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

import PediatricsEMAStore from '../../../store/PediatricsEMA';

// utils
import usePediatricsEMAData from '../hooks/usePediatricsEMAData';

import ReportWidgets from '../../../components/Report/Widgets';
import { PEDIATRICS_EMA_FILTERS } from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import { PAGINATION_DEFAULT_OPTIONS } from '../../PediatricsFDA/const';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import Insights from '../../../components/Insights/Insights';

const PediatricTabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const {
    isLoading,
    pediatricsEMAFilters,
    getPediatricsEMAData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = usePediatricsEMAData();
  const { pediatricsEMAState } = useContext(PediatricsEMAStore);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  const [pediatricStats, setPediatricStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getPediatricsEMAData(
      [],
      PAGINATION_DEFAULT_OPTIONS.limit,
      PAGINATION_DEFAULT_OPTIONS.offset,
      []
    );
  }, []);

  useEffect(() => {
    if (pediatricsEMAState?.stats) {
      const newStats: Stat[] = [];
      const [activeIngredients, formulations, routes] =
        pediatricsEMAState?.stats?.overall_stats || [];

      newStats.push({ title: 'Active Ingredients', value: activeIngredients?.count });
      newStats.push({ title: 'Formulations', value: formulations?.count });
      newStats.push({ title: 'Routes', value: routes?.count });

      newStats.push({
        title: 'Documents/Pages',
        value: pediatricsEMAState?.stats?.pdf_stats?.total_document_count,
        value2: pediatricsEMAState?.stats?.pdf_stats?.total_page_count
      });

      setPediatricStats(newStats);
    }
  }, [pediatricsEMAFilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getPediatricsEMAData(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return PEDIATRICS_EMA_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getAriaDocuments = () => {
    const ariaDocuments = pediatricsEMAState?.askRiaFilters?.map((item: any) => ({
      identifier: item.replace(/\//g, '-')
    }));

    return ariaDocuments || [];
  };

  const getInsightStatsList = useMemo(() => {
    const StatList: Array<any> = [];
    if (pediatricsEMAState?.stats?.overall_stats) {
      pediatricsEMAState?.stats?.overall_stats.forEach((item: { title: any; count: any }) => {
        StatList.push({
          text: item.title,
          count: item.count,
          toolTipValue: ''
        });
      });
      StatList.push({
        text: 'Total Documents | Pages',
        count: `${truncateCountAboveMillions(
          pediatricsEMAState?.stats?.pdf_stats?.total_document_count
        )} | ${truncateCountAboveMillions(pediatricsEMAState?.stats?.pdf_stats?.total_page_count)}`,
        toolTipValue: `${formatCount(
          pediatricsEMAState?.stats?.pdf_stats?.total_document_count
        )} | ${formatCount(pediatricsEMAState?.stats?.pdf_stats?.total_page_count)}`
      });
    }
    return StatList;
  }, [pediatricsEMAState]);

  const getLayer1Charts = useMemo(() => {
    const pipsGraph = VISUALIZE_CHART_MAPPING['ema-pips']?.layer1;

    if (pipsGraph && pediatricsEMAState?.stats?.year_graph) {
      const updatedGraph = pipsGraph.map((graph: any) => ({
        ...graph,
        data: pediatricsEMAState?.stats?.year_graph
      }));

      return updatedGraph;
    }
    return pipsGraph;
  }, [pediatricsEMAState?.stats?.year_graph]);

  const getLayer2Charts = useMemo(() => {
    const pipsGraph = VISUALIZE_CHART_MAPPING['ema-pips']?.layer2;
    if (pipsGraph) {
      pipsGraph.forEach(graph => {
        if (graph?.id === 'formulations' && pediatricsEMAState?.stats?.formulations_stats) {
          // eslint-disable-next-line no-param-reassign
          graph.data = pediatricsEMAState?.stats?.formulations_stats;
        }
        if (graph?.id === 'route' && pediatricsEMAState?.stats?.route_stats) {
          // eslint-disable-next-line no-param-reassign
          graph.data = pediatricsEMAState?.stats?.route_stats;
        }
      });

      return pipsGraph;
    }
    return pipsGraph;
  }, [pediatricsEMAState]);

  return (
    <>
      <SubHeader
        title='EMA pediatric investigation plan'
        subTitle='Showing list of pediatric investigation plan published by EMA'
        stats={pediatricStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'ema-pips',
                  moduleName: 'pips',
                  disableSelectionSource: true,
                  filters: pediatricsEMAState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'ema_pips',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <PipsListView
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getPediatricsEMAData={getPediatricsEMAData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>
      {ariaModalOpen && (
        <AriaDrawerCore
          applications={pediatricsEMAState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ eu: ['pip'] }}
          groupId='entity_id'
          docCount={pediatricsEMAState?.stats?.pdf_stats?.total_document_count}
          docPageCount={pediatricsEMAState?.stats?.pdf_stats?.total_page_count}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={pediatricsEMAFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={pediatricsEMAState.availableFilters}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='ema-pips'
          searchType='pips'
          appliedFilter={pediatricsEMAState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default memo(PediatricTabs);

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import Box from '@mui/material/Box';

import Popover from '@mui/material/Popover';

import GlobalStore from '../../../store';
import store from '../../../store/Letters';
// components
import LettersDetails from '../components/Details';
import useLettersData from '../hooks/useLettersData';

import ReportWidgets from '../../../components/Report/Widgets';
import { LETTERS_FILTERS, PAGINATION_DEFAULT_OPTIONS } from '../const';
import SubHeader from '../../../components/SubHeader';
import { Stat } from '../../SearchResults/types';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';

// Not a True HOC.
const LettersTabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { lettersState } = useContext(store);
  const {
    lettersfilters,
    isLoading,
    isFilterApplied,
    getLettersData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = useLettersData();
  const [letterStats, setLetterStats] = useState<Stat[]>([]);
  const [ariaModalOpen, setAriaModalOpen] = useState<boolean>(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getLettersData([], PAGINATION_DEFAULT_OPTIONS.limit, PAGINATION_DEFAULT_OPTIONS.offset, []);
  }, []);

  useEffect(() => {
    if (lettersState?.lettersStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: lettersState.lettersStats.totalLettersIssued || 0
      });
      newStats.push({
        title: 'Letters Closed',
        value: lettersState.lettersStats.totalClosedLetters || 0
      });
      newStats.push({
        title: 'Issued in Last 30 Days',
        value: lettersState.lettersStats.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Total Documents | Pages',
        value: lettersState.lettersStats?.pdfCount,
        value2: lettersState.lettersStats?.pageCount
      });

      setLetterStats(newStats);
    }
  }, [lettersfilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getLettersData(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return LETTERS_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.id] = elem.label;
      return acc;
    }, {});
  };
  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };
  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getAriaDocuments = () => {
    const ariaDocuments = lettersState?.askRiaFilters?.map((item: any) => ({
      identifier: item.toString()
    }));

    return ariaDocuments || [];
  };

  const getInsightStatsList = useMemo(() => {
    if (lettersState?.lettersStats) {
      return [
        {
          text: 'Total Letters Issued',
          count: lettersState?.lettersStats?.totalLettersIssued,
          toolTipValue: ''
        },
        {
          text: 'Total Letters Closed',
          count: lettersState?.lettersStats?.totalClosedLetters,
          toolTipValue: ''
        },
        {
          text: 'Letter Issued in Last 30 Days',
          count: lettersState?.lettersStats?.totalLastThirtyDays,
          toolTipValue: ''
        },
        {
          text: 'Total Documents | Pages',
          count: `${truncateCountAboveMillions(
            lettersState?.lettersStats?.pdfCount
          )} | ${truncateCountAboveMillions(lettersState?.lettersStats?.pageCount)}`,
          toolTipValue: `${formatCount(lettersState?.lettersStats?.pdfCount)} | ${formatCount(
            lettersState?.lettersStats?.pageCount
          )}`
        }
      ];
    }
    return [];
  }, [lettersState?.lettersStats]);

  const getLayer1Charts = useMemo(() => {
    const lettersGraph = VISUALIZE_CHART_MAPPING['fda-letters']?.layer1;

    if (lettersGraph && lettersState?.issueDateBar) {
      const updatedGraph = lettersGraph.map((graph: any) => ({
        ...graph,
        data: lettersState?.issueDateBar
      }));

      return updatedGraph;
    }
    return lettersGraph;
  }, [lettersState.issueDateBar]);

  const getLayer2Charts = useMemo(() => {
    const lettersGraph = VISUALIZE_CHART_MAPPING?.['fda-letters'].layer2;

    if (lettersGraph && lettersState?.treemapCharts) {
      lettersGraph.forEach(graph => {
        const donutItem = lettersState.treemapCharts.find(
          (donut: { apiKey: string }) => donut?.apiKey === graph?.id
        );
        if (donutItem) {
          // eslint-disable-next-line no-param-reassign
          graph.data = donutItem.children;
        }
      });

      return lettersGraph;
    }
    return lettersGraph;
  }, [lettersState?.treemapCharts]);

  return (
    <>
      <SubHeader
        title='FDA Warnings and Untitled Letters'
        subTitle='FDA Warning, Untitled and Closeout Letters'
        stats={letterStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'fda-letters',
                moduleName: 'letters',
                disableSelectionSource: true,
                filters: lettersState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'fda_letters',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}
      <LettersDetails
        pagination={pagination}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
        sortFields={sortFields}
        getLettersData={getLettersData}
        isFilterUpdated={isFilterUpdated}
        setIsFilterUpdated={setIsFilterUpdated}
        getDatawithDataGridFilter={getDatawithDataGridFilter}
        dataGridFilter={dataGridFilter}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='fda-letters'
          searchType='letters'
          appliedFilter={lettersState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>
      <NewFilters
        data={lettersfilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={lettersState.availableFilters}
      />

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={isFilterApplied ? getAriaDocuments() : []}
          sources={{ us: ['fda-letters'] }}
          groupId='entity_id'
          docCount={lettersState.lettersStats?.pdfCount}
          docPageCount={lettersState.lettersStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(LettersTabs);

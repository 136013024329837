import React, { useCallback, useContext } from 'react';

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import styles from './styles';
import StatsCountTile from '../../helpers/baseComponents/StatsCountTile';
import ReportButton from '../Report/ReportButton';
import BarChart from './BarChart';
import PieChart from './PieChart';

// store
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

type InsightProps = {
  statsList: {
    text: string;
    count: any;
    toolTipValue?: string;
  }[];
  layer1Component: any;
  layer2Component: any;
  isLoading: boolean;
  appliedFilter: any;
  source: string;
  searchType: string;
};

const GraphBox = ({
  graph,
  isLoading,
  addToReport
}: {
  graph: any;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  addToReport: (data: any) => Promise<void>;
}) => (
  <Grid
    item
    xs={6}
    lg={12}
    sx={{
      padding: 2,
      backgroundColor: 'white.main',
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
    key={graph?.id}>
    <Box height='10%' display='flex' justifyContent='space-between'>
      {!isLoading ? (
        <>
          <Typography sx={styles.graphHeading}>{graph?.title?.title}</Typography>
          {!graph?.disableReport && <ReportButton data={graph} addToReport={addToReport} />}
        </>
      ) : (
        <>
          <Skeleton height='100%' width='20%' />
          <Skeleton height='100%' width='20%' />
        </>
      )}
    </Box>
    {graph?.graphType === 'BAR' && <BarChart graphData={graph} loading={isLoading} />}
    {graph?.graphType === 'PIE' && <PieChart graphData={graph} loading={isLoading} />}
  </Grid>
);

const GraphLayer = ({
  layerComponent,
  layerStyle,
  isLoading,
  addToReport
}: {
  layerComponent: any[];
  layerStyle: any;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  addToReport: (data: any) => Promise<void>;
}) => (
  <Box display='flex' flexDirection='row' sx={layerStyle}>
    {layerComponent.map((graph: any) => (
      <GraphBox graph={graph} isLoading={isLoading} key={graph?.id} addToReport={addToReport} />
    ))}
  </Box>
);

const Insights: React.FC<InsightProps> = ({
  statsList,
  isLoading,
  source,
  searchType,
  appliedFilter,
  layer1Component,
  layer2Component
}) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const addToReport = useCallback(
    async (data: any) => {
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: data?.graphType,
          sectionType: 'CHART',
          id: uniqueId(`${searchType}_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: data?.graphStyle,
            title: data?.title
          },
          data: {
            source,
            search_type: searchType,
            disableSelectionSource: true,
            filters: appliedFilter,
            query: data?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [dispatch, layer1Component, layer2Component, appliedFilter]
  );
  return (
    <Grid height='100%' m={0} container spacing={2}>
      <Grid item xs={2} p='0px !important'>
        <Stack spacing={2.5} direction='column'>
          {statsList && !isLoading ? (
            <>
              {statsList.map((stats: any) => (
                <StatsCountTile
                  key={stats?.text}
                  text={stats?.text}
                  count={stats?.count}
                  toolTipValue={stats?.toolTipValue}
                />
              ))}
            </>
          ) : (
            <Stack display='flex' flexDirection='column'>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={10} pt='0px !important'>
        <Box display='flex' flexDirection='column' sx={styles.box}>
          <GraphLayer
            layerComponent={layer1Component}
            layerStyle={styles.layer1Charts}
            isLoading={isLoading}
            addToReport={addToReport}
          />
          <GraphLayer
            layerComponent={layer2Component}
            layerStyle={styles.layer2Charts}
            isLoading={isLoading}
            addToReport={addToReport}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(Insights);

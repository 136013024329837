/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';
// style
import CustomPieChartStyles from './CustomPieChart.styles';

const ResponsivePie = lazy(() =>
  import('@nivo/pie').then(module => ({ default: module.ResponsivePie }))
);

const CustomPieChart = ({
  data,
  margin,
  innerRadius,
  colors,
  fillText,
  radialLabelsTextColor,
  sliceLabelsTextColor,
  legends = null,
  enableArcLinkLabels = true,
  arcLabelsSkipAngle = 10,
  arcLinkLabelsSkipAngle = 10
}) => {
  const classes = CustomPieChartStyles();
  const titleCase = st => {
    return st?.split(' ')?.reduce((s, c) => `${s}${c.charAt(0).toUpperCase() + c.slice(1)} `, '');
  };

  data.forEach(ele => {
    // eslint-disable-next-line no-param-reassign
    ele.id = titleCase(ele.id || ele.key);
  });

  return (
    <Suspense fallback={<SuspenseCircularLoader />}>
      <ResponsivePie
        className={classes.root}
        data={data}
        valueFormat=' >-d'
        margin={margin || { top: 40, right: 40, bottom: 20, left: 30 }}
        innerRadius={innerRadius || 0.2}
        padAngle={0.7}
        cornerRadius={0}
        startAngle={-50}
        // colors={['#40C4FF', '#0E83DC']}
        colors={colors || ['#48E0A6', '#0A467C', '#008B8B', '#318AC8']}
        sliceLabel={e => {
          return `${e.id || e.key}(${e.value})`;
        }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        // enableRadialLabels={false}
        radialLabelsLinkOffset={-10}
        radialLabelsLinkDiagonalLength={20}
        radialLabelsLinkHorizontalLength={10}
        arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle || 10}
        arcLabelsSkipAngle={arcLabelsSkipAngle || 10}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor={radialLabelsTextColor || '#1FBDCA'}
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        arcLabelsTextColor={['#fff']}
        sliceLabelsTextColor={sliceLabelsTextColor || '#fff'}
        theme={{
          axis: {
            fontSize: 14,
            tickColor: '#000',
            ticks: {
              line: {
                stroke: '#555555'
              },
              text: {
                fill: fillText || '#1FBDCA',
                fontWeight: 'bold'
              }
            }
          }
        }}
        enableArcLinkLabels={enableArcLinkLabels}
        legends={legends || []}
      />
    </Suspense>
  );
};

export default CustomPieChart;

import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

import NewFilters from '../../NewFilters';

import PMRStore from '../../../store/PMRImpact';

// hooks
import usePMRImpactData from '../hook/usePMRImpact';
// components
import PMRImpactDetails from '../components/Details';
import ReportWidgets from '../../Report/Widgets';
import SubHeader from '../../SubHeader';
import BottomDrawer from '../../CustomComponents/BottomDrawer';

import { PAGINATION_DEFAULT_OPTIONS, PMR_IMPACT_FILTERS } from '../const';
import { Stat } from '../../../pages/SearchResults/types';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import VISUALIZE_CHART_MAPPING from '../../Insights/const';
import Insights from '../../Insights/Insights';

const PMRImpactTabs = () => {
  const { pmrState } = useContext(PMRStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const {
    isLoading,
    pmrImpactFilters,
    getPMRData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = usePMRImpactData();
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  const [pmrStats, setPmrStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getPMRData([], pagination.pageSize, pagination.page, []);
  }, []);

  useEffect(() => {
    if (pmrState?.pmrStats) {
      const newStats: Stat[] = [];
      newStats.push({ title: 'Total PMR/PMC', value: pmrState?.pmrStats?.total });
      newStats.push({
        title: 'Active Ingredients',
        value: pmrState?.pmrStats?.total_active_ingredients
      });
      newStats.push({
        title: 'Documents/Pages',
        value: pmrState?.pmrStats?.pdf_stats?.total_document_count,
        value2: pmrState?.pmrStats?.pdf_stats?.total_page_count
      });

      setPmrStats(newStats);
    }
  }, [pmrImpactFilters]);

  const handleFilterApply = useCallback((currentfilters: any) => {
    setIsFilterUpdated(true);
    setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
    getPMRData(currentfilters, pagination.pageSize, PAGINATION_DEFAULT_OPTIONS.offset, sortFields);
  }, []);

  const getFiltersLabelList = () => {
    return PMR_IMPACT_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getInsightStatsList = useMemo(() => {
    if (pmrState?.pmrStats) {
      return [
        {
          text: 'Total PMR/PMC',
          count: pmrState?.pmrStats?.total,
          toolTipValue: ''
        },
        {
          text: 'Active Ingredients',
          count: pmrState?.pmrStats?.total_active_ingredients,
          toolTipValue: ''
        },
        {
          text: 'Total Documents ',
          count: truncateCountAboveMillions(pmrState?.pmrStats?.pdf_stats?.total_document_count),
          toolTipValue: formatCount(pmrState?.pmrStats?.pdf_stats?.total_document_count)
        },
        {
          text: 'Total Pages ',
          count: truncateCountAboveMillions(pmrState?.pmrStats?.pdf_stats?.total_page_count),
          toolTipValue: formatCount(pmrState?.pmrStats?.pdf_stats?.total_page_count)
        }
      ];
    }
    return [];
  }, [pmrState?.pmrStats]);

  const getLayer1Charts = useMemo(() => {
    const pmrGraph = VISUALIZE_CHART_MAPPING?.pmr?.layer1;

    if (pmrGraph && pmrState?.categoryGraph) {
      const updatedGraph = pmrGraph.map((graph: any) => ({
        ...graph,
        data: pmrState?.categoryGraph
      }));

      return updatedGraph;
    }
    return pmrGraph;
  }, [pmrState?.categoryGraph]);

  const getLayer2Charts = useMemo(() => {
    const pmrGraph = VISUALIZE_CHART_MAPPING?.pmr?.layer2;
    if (pmrGraph) {
      pmrGraph.forEach(graph => {
        if (graph?.id === 'pmrs_issued_by_year' && pmrState?.issueYearGraph) {
          // eslint-disable-next-line no-param-reassign
          graph.data = pmrState?.issueYearGraph;
        }
        if (graph?.id === 'fulfilled_year' && pmrState?.fulfillmentGraph) {
          // eslint-disable-next-line no-param-reassign
          graph.data = pmrState?.fulfillmentGraph;
        }
      });

      return pmrGraph;
    }
    return pmrGraph;
  }, [pmrState]);

  return (
    <>
      <SubHeader
        title='FDA PMR/C Impact'
        subTitle='Impact of FDA Postmarketing Requirements and Commitments'
        stats={pmrStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'pmr',
                  moduleName: 'impact',
                  disableSelectionSource: true,
                  filters: pmrState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'impact',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <PMRImpactDetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getPMRData={getPMRData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>
      <NewFilters
        data={pmrImpactFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={pmrState.availableFilters}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='pmr'
          searchType='impact'
          appliedFilter={pmrState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>
    </>
  );
};

export default memo(PMRImpactTabs);
